import React from 'react';

import { styled } from '@mui/material/styles';
const PREFIX = 'DRO';

const classes = {
  dro: `${PREFIX}-dro`,
  pre: `${PREFIX}-pre`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.dro}`]: {
    borderRadius: 10,
    padding: "10px",
    backgroundColor: "rgba(100,100,100,.75)",
    color: "white",
    marginTop: 10
  },

  [`& .${classes.pre}`]: {
    padding: 0,
    margin: 0
  }
}));
const DRO = ((props) => {
  const {
    X,Y,Z,A,B,C,T
  } = props;

  return (
    <Root>
      <div className={classes.dro }>
        <pre className={classes.pre}>
        <div>X: <span style={{ display: "block", float: "right" }}>{ X }</span></div>
        <div>Y: <span style={{display: "block", float: "right"}}>{ Y }</span></div>
        <div>Z: <span style={{display: "block", float: "right"}}>{ Z }</span></div>
        { typeof(A) !== "undefined" ? <div>A: <span style={{display: "block", float: "right"}}>{ A }</span></div> : null }
        { typeof(B) !== "undefined" ? <div>B: <span style={{display: "block", float: "right"}}>{ B }</span></div> : null }
        { typeof(C) !== "undefined" ? <div>C: <span style={{display: "block", float: "right"}}>{ C }</span></div> : null }
        <div>T: <span style={{display: "block", float: "right"}}>{ T }</span></div>
        </pre>
      </div>
    </Root>
  );
});

export default DRO;

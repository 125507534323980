import { styled } from '@mui/material/styles';
import DRO from '../containers/DRO';

const PREFIX = 'DROPane';

const classes = {
  droPane: `${PREFIX}-droPane`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.droPane}`]: {
    position: "absolute",
    right: "20px",
    top: "96px",
    zIndex: 10
  }
}));

function DROPane(props) {
  return (
    <Root>
    <div className={classes.droPane}>
       <DRO/>
     </div>
    </Root>
  );
}

export default (DROPane);

import React, { useCallback, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import machines from '../machines';

const machinesList = Object.entries(machines).filter(([id,machine]) => !machine.isSuperSecret || window.location.search.includes("showSuperSecretMachines")).sort((a,b) => {
  if(a[1].name < b[1].name) {
    return -1;
  } else if(a[1].name > b[1].name) {
    return 1;
  }
  return 0;
});

function MachineLinks(props) {
  const theme = useTheme();

  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);

  const handleClickOutOfMenu = useCallback(() => {
    setMenuOpen(false);
  }, [ setMenuOpen ]);

  const handleOpenMenu = useCallback((e) => {
    setMenuOpen(true);
    setAnchorEl(e.currentTarget);
  }, [ setMenuOpen, setAnchorEl ]);

  const handleMachineChange = useCallback((id) => (e) => {
    setMenuOpen(false);

    const element = document.createElement('a');
    element.setAttribute('href', document.location.protocol + "//" + document.location.host + "?machine=" + id);
    element.click();

  }, [ setMenuOpen ]);

  return (
    <div style={{
      position: "fixed",
      right: 0,
      bottom: 0,
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1)
    }}>
      <Button variant="contained" onClick={handleOpenMenu}>Change Machine</Button>
      <Menu open={menuOpen} anchorEl={anchorEl} onClose={handleClickOutOfMenu}>
        { machinesList.map(([ id, m ]) => <MenuItem key={id} onClick={handleMachineChange(id)}>{ m.name }</MenuItem> ) }
      </Menu>
    </div>
  );
}

export default (MachineLinks);
